import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { useCurrentUser } from '../../api/Me';

export const useAnalytics = () => {
  const [posthogInitialized, setPosthogInitialized] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_POSTHOG_API_KEY) {
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
        // Required for Sentry integration.
        ui_host: 'https://app.posthog.com',
        api_host: process.env.NEXT_PUBLIC_POSTHOG_API_HOST,
        disable_session_recording: true,
      });
      setPosthogInitialized(true);
    }
  }, []);

  const { data: currentUser, isLoading, error } = useCurrentUser();
  useEffect(() => {
    if (!isLoading && !error && currentUser && posthogInitialized) {
      posthog.identify(currentUser.id);
      window.ko?.identify({ email: currentUser.email, name: currentUser.name, id: currentUser.id, org: currentUser.organization_name });
    }
  }, [currentUser, error, isLoading, posthogInitialized]);

  useEffect(() => {
    const handleRouteChange = () => posthog.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, posthogInitialized]);
};
