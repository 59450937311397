import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { SWRConfig } from 'swr';
import { api } from '../../api';
import { ToastVariant, useToasts } from '../feedback/Toast';
import { redirectToLoginOrDashboard } from "@/utils/url";


interface APIProviderProps {
  children: ReactNode;
}

export const APIProvider = ({ children }: APIProviderProps) => {
  const router = useRouter();
  const { showToast } = useToasts();

  const isExcludedPath = (path: string) => {
    const excludedPaths = new Set([
      '/',
      '/404',
      '/about',
      '/security',
      '/terms',
      '/privacy',
      '/pricing',
      '/contact',
      '/create-organization',
    ]);

    return excludedPaths.has(path) || path.includes('blog') || path.includes('case-studies');
  };


  return (
    <SWRConfig
      value={{
        fetcher: async (url) => {
          try {
            const response = await api.get(url);
            return response.data;
          } catch (error) {
            if (error instanceof AxiosError && error.response?.status) {
              const { status, data } = error.response;
              switch (status) {
                case 401:
                  if (!isExcludedPath(router.pathname)) {
                    showToast({
                      title:
                        'Your session has expired. Please log in to continue.',
                      variant: ToastVariant.ERROR,
                    });
                  router.push(redirectToLoginOrDashboard());
                  }
                  break;
                case 404: {
                  const errorMessage = error.response.data?.errorMessage;
                  showToast({
                    title: errorMessage ?? 'Page not found',
                    variant: ToastVariant.ERROR,
                  });
                  router.push(`/dashboard`);
                  break;
                }
              }
            }

            throw error;
          }
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};
