import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as ToastPrimitive from '@radix-ui/react-toast';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { keyframes, styled } from '../../stitches.config';
import { Stack } from '../layout/Stack';
import { Text } from '../typography/Text';

export const GENERIC_ERROR_MESSAGE =
  'An unexpected error occurred. Please try again or contact us.';

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const slideIn = keyframes({
  from: { transform: `translateY(calc(100% + $space$large))` },
  to: { transform: 'translateY(0)' },
});

const swipeOut = keyframes({
  from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
  to: { transform: `translateX(calc(100% + $space$large))` },
});

export enum ToastVariant {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
}

export const ToastViewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: '$large',
  gap: '$small',
  width: 400,
  maxWidth: '100vw',
  boxSizing: 'content-box',
  outline: 'none',
  zIndex: Number.MAX_SAFE_INTEGER,
  '@smallDevices': {
    width: '100%',
    boxSizing: 'border-box',
    padding: '$medium',
  },
});

const ToastRoot = styled(ToastPrimitive.Root, {
  borderRadius: '$default',
  boxShadow: '$elevation-large, inset 0px 0px 0px 1px $colors$silvermist-100',
  padding: '$medium',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '$small',
  background: '$white',
  variants: {
    variant: {
      info: {
        color: '$silvermist-500',
      },
      error: {
        color: '$bloodmoon-500',
      },
      success: {
        color: '$pineglade-500',
      },
    },
  },
  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in`,
    },
    '&[data-swipe="move"]': {
      transform: 'translateX(var(--radix-toast-swipe-move-x))',
    },
    '&[data-swipe="cancel"]': {
      transform: 'translateX(0)',
      transition: 'transform 200ms ease-out',
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out`,
    },
  },
});

const ToastContent = styled('div', {
  flex: '1 1 0',
  minWidth: 0,
});

const ToastIcon = styled('div', {
  fontSize: '$large',
});

const DismissButton = styled('button', {
  flex: '0 0 auto',
  padding: '$xsmall',
  margin: '-$xsmall -$xsmall -$xsmall 0',
  borderRadius: '$round',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$silvermist-500',
  cursor: 'pointer',
  fontSize: 20,
  outline: 'none',
  transition:
    'box-shadow $medium ease, color $medium ease, transform $medium ease, opacity $medium ease',
  '&:hover': {
    color: '$silvermist-950',
  },
  '&:focus-visible': {
    boxShadow: '0 0 0 2px $colors$lupine-600',
    color: '$silvermist-950',
  },
  '&:active': {
    transform: 'scale(0.9)',
  },
});

const ICONS: Record<
  ToastVariant,
  React.ForwardRefExoticComponent<React.SVGAttributes<SVGElement>>
> = {
  info: InformationCircleIcon,
  success: CheckCircleIcon,
  error: XCircleIcon,
};

interface ToastMessage {
  title: string;
  message?: string;
  variant?: ToastVariant;
}

interface InternalToastMessage extends ToastMessage {
  at: number;
}

export interface ToastContextData {
  messages: ToastMessage[];
  showToast(message: ToastMessage): void;
}

export const ToastContext = createContext<ToastContextData>({
  messages: [],
  showToast: () => null,
});

export const useToasts = () => useContext(ToastContext);

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [messages, setMessages] = useState<InternalToastMessage[]>([]);
  const showToast = useCallback(
    (message: ToastMessage) => {
      setMessages([{ ...message, at: new Date().getMilliseconds() }]);
    },
    [setMessages],
  );

  return (
    <ToastContext.Provider value={{ messages, showToast }}>
      <ToastPrimitive.Provider>
        {children}
        <ToastViewport />
        {messages.map((message) => (
          <Toast
            key={message.at}
            variant={message.variant}
            title={message.title}
            message={message.message}
            onOpenChange={() =>
              setMessages((messages) => {
                const indexToRemove = messages.indexOf(message);
                const newMessages = [...messages];
                newMessages.splice(indexToRemove, 1);
                return newMessages;
              })
            }
          />
        ))}
      </ToastPrimitive.Provider>
    </ToastContext.Provider>
  );
};

export function Toast({
  variant = ToastVariant.INFO,
  onOpenChange,
  title,
  message,
}: {
  variant?: ToastVariant;
  onOpenChange: (open: boolean) => void;
  title: string;
  message?: string;
}) {
  const Icon = ICONS[variant];

  return (
    <ToastRoot variant={variant} onOpenChange={onOpenChange}>
      <ToastIcon>
        <Icon width="1em" height="1em" />
      </ToastIcon>
      <ToastContent>
        <Stack spacing="medium">
          <ToastPrimitive.Title asChild>
            <Text as="h2" size="small" weight="medium">
              {title}
            </Text>
          </ToastPrimitive.Title>
          {message ? (
            <ToastPrimitive.Description asChild>
              <Text size="small" color="tertiary">
                {message}
              </Text>
            </ToastPrimitive.Description>
          ) : null}
        </Stack>
      </ToastContent>
      <ToastPrimitive.Close asChild>
        <DismissButton
          type="button"
          onClick={() => onOpenChange(false)}
          aria-label="dismiss this message"
        >
          <XMarkIcon width="1em" height="1em" />
        </DismissButton>
      </ToastPrimitive.Close>
    </ToastRoot>
  );
}
