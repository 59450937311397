export const SENTRY_ORGANIZATION = 'wolfia';

export const SENTRY_PROJECT_ID = '4507034214793216';

export const SENTRY_DSN = `https://30e538f50ca90eb5de6703b8e9e189bd@o4507034213023744.ingest.us.sentry.io/${SENTRY_PROJECT_ID}`;

export const beforeSend = (event, hint) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(hint.originalException || hint.syntheticException);

    // Drop the event and don't send to Sentry.
    return null;
  }

  return event;
};
