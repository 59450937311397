import { setUser } from '@sentry/browser';
import { useEffect } from 'react';
import { useCurrentUser } from '../../api/Me';

export const useMonitoringInitialization = () => {
  const { data: currentUser } = useCurrentUser();

  useEffect(() => {
    if (currentUser) {
      setUser({
        id: currentUser.id,
        email: currentUser.email,
      });
    }
  }, [currentUser]);
};
