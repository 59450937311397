import { ToastProvider } from '../feedback/Toast';
import { APIProvider } from './APIProvider';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { SidebarProvider } from './SidebarProvider';
import React from "react";
import { Toaster } from "@/components/ui/sonner";

interface Props {
  children: React.ReactNode;
}

export const GlobalProviders = ({ children }: Props) => {
  return (
    <ToastProvider>
      <APIProvider>
        <SidebarProvider>
          <TooltipProvider delayDuration={0}>{children}</TooltipProvider>
          <Toaster />
        </SidebarProvider>
      </APIProvider>
    </ToastProvider>
  );
};
