import { ComponentProps, ReactNode } from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import { styled } from '../../stitches.config';

const StackContainer = styled('div', {
  all: 'unset',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  defaultVariants: {
    spacing: 'medium',
    width: 'full',
  },
  variants: {
    spacing: {
      xsmall: { gap: '$xsmall' },
      small: { gap: '$small' },
      medium: { gap: '$medium' },
      large: { gap: '$large' },
      xlarge: { gap: '$xlarge' },
      xxlarge: { gap: '$xxxlarge' },
      xxxlarge: { gap: '$xxxxxlarge' },
    },
    width: {
      full: {
        width: '100%',
      },
      large: {
        width: '80%',
      },
      half: {
        width: '50%',
      },
      medium: {
        width: '80%',
      },
      small: {
        width: '40%',
      },
    },
  },
});

const StackItem = styled('div', {
  defaultVariants: {
    align: 'left',
  },
  variants: {
    align: {
      left: {
        display: 'block',
      },
      center: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      right: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      },
    },
  },
});

interface Props {
  as?: 'ul';
  children: ReactNode;
  align?: ComponentProps<typeof StackItem>['align'];
  spacing?: ComponentProps<typeof StackContainer>['spacing'];
  width?: ComponentProps<typeof StackContainer>['width'];
  ariaDescribedBy?: string;
  style?: any;
}

export const Stack = ({
  as,
  children,
  align,
  spacing,
  width,
  ariaDescribedBy,
  style,
}: Props) => {
  const stackItems = flattenChildren(children);

  return (
    <StackContainer
      spacing={spacing}
      as={as}
      width={width}
      aria-describedby={ariaDescribedBy}
      style={style}
    >
      {stackItems.map((item, index) => (
        <StackItem key={index} align={align} as={as == 'ul' ? 'li' : undefined}>
          {item}
        </StackItem>
      ))}
    </StackContainer>
  );
};
