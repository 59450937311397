import {createStitches} from '@stitches/react';
import {normalize} from 'stitches-normalize-css';

export const BASIC_COLORS = {
  white: '#FFFFFF',
  black: '#000000',
};

export const SILVERMIST_COLORS = {
  'silvermist-50': '#f6f6f9',
  'silvermist-100': '#edecf2',
  'silvermist-200': '#d6d4e3',
  'silvermist-300': '#b2afca',
  'silvermist-400': '#8a88a8',
  'silvermist-500': '#6c698e',
  'silvermist-600': '#575277',
  'silvermist-700': '#464262',
  'silvermist-800': '#3b3854',
  'silvermist-900': '#353149',
  'silvermist-950': '#191722',
};

export const LUPINE_COLORS = {
  'lupine-50': '#eef0ff',
  'lupine-100': '#e0e3ff',
  'lupine-200': '#c7cbfe',
  'lupine-300': '#a5a9fc',
  'lupine-400': '#8581f8',
  'lupine-500': '#7163f1',
  'lupine-600': '#6345e6',
  'lupine-700': '#5538ca',
  'lupine-800': '#4530a3',
  'lupine-900': '#3b2e81',
  'lupine-950': '#220e67',
};

export const BLOODMOON_COLORS = {
  'bloodmoon-50': '#fdf2f8',
  'bloodmoon-100': '#fde6f3',
  'bloodmoon-200': '#fdcde7',
  'bloodmoon-300': '#fca5d3',
  'bloodmoon-400': '#f96db4',
  'bloodmoon-500': '#f24297',
  'bloodmoon-600': '#e22074',
  'bloodmoon-700': '#c4125a',
  'bloodmoon-800': '#a2124a',
  'bloodmoon-900': '#871441',
  'bloodmoon-950': '#530422',
};

export const SUNBARK_COLORS = {
  'sunbark-50': '#fff7ed',
  'sunbark-100': '#feedd6',
  'sunbark-200': '#fcd7ac',
  'sunbark-300': '#faba77',
  'sunbark-400': '#f79240',
  'sunbark-500': '#f5741a',
  'sunbark-600': '#e65910',
  'sunbark-700': '#bf420f',
  'sunbark-800': '#a23916',
  'sunbark-900': '#7a2e14',
  'sunbark-950': '#421508',
};

export const PINEGLADE_COLORS = {
  'pineglade-50': '#edfcf3',
  'pineglade-100': '#d3f8e1',
  'pineglade-200': '#abefc9',
  'pineglade-300': '#74e1ab',
  'pineglade-400': '#3ccb88',
  'pineglade-500': '#16a265',
  'pineglade-600': '#0c8f58',
  'pineglade-700': '#0a724a',
  'pineglade-800': '#0a5b3c',
  'pineglade-900': '#094b32',
  'pineglade-950': '#042a1d',
};

export const DARK_BASIC_COLORS = {
  black: '#FFFFFF', // Inverted
  white: '#000000', // Inverted
};

export const DARK_SILVERMIST_COLORS = {
  'silvermist-950': '#e6e6e9', // Lightest becomes darkest
  'silvermist-900': '#ededf0',
  'silvermist-800': '#f3f3f6',
  'silvermist-700': '#f9f9fb',
  'silvermist-600': '#ffffff', // Approximation for inverted
  'silvermist-500': '#d1d1d4',
  'silvermist-400': '#b3b3b6',
  'silvermist-300': '#959598',
  'silvermist-200': '#78787b',
  'silvermist-100': '#5a5a5d',
  'silvermist-50': '#3c3c3f', // Darkest becomes lightest
};

export const DARK_LUPINE_COLORS = {
  'lupine-950': '#ddedff', // Approximating an inversion
  'lupine-900': '#bacdff',
  'lupine-800': '#95abff',
  'lupine-700': '#7189ff',
  'lupine-600': '#4c67ff',
  'lupine-500': '#2945ff',
  'lupine-400': '#0623ff',
  'lupine-300': '#030fad',
  'lupine-200': '#020891',
  'lupine-100': '#010574',
  'lupine-50': '#000357', // Darkest in light theme becomes lightest in dark
};

export const DARK_BLOODMOON_COLORS = {
  'bloodmoon-950': '#facbdd',
  'bloodmoon-900': '#f7a6c8',
  'bloodmoon-800': '#f481b3',
  'bloodmoon-700': '#f15b9e',
  'bloodmoon-600': '#ee3689',
  'bloodmoon-500': '#eb1063',
  'bloodmoon-400': '#c80e4f',
  'bloodmoon-300': '#a50c3b',
  'bloodmoon-200': '#830a27',
  'bloodmoon-100': '#610813',
  'bloodmoon-50': '#3f0600',
};

export const DARK_SUNBARK_COLORS = {
  'sunbark-950': '#ffe2bc',
  'sunbark-900': '#ffce92',
  'sunbark-800': '#ffba68',
  'sunbark-700': '#ffa63e',
  'sunbark-600': '#ff9214',
  'sunbark-500': '#e67f10',
  'sunbark-400': '#bf6c0d',
  'sunbark-300': '#99590a',
  'sunbark-200': '#734507',
  'sunbark-100': '#4d3104',
  'sunbark-50': '#261e01',
};

export const DARK_PINEGLADE_COLORS = {
  'pineglade-950': '#b1e3cc',
  'pineglade-900': '#8fd6b4',
  'pineglade-800': '#6ec99c',
  'pineglade-700': '#4dbb84',
  'pineglade-600': '#2cae6c',
  'pineglade-500': '#0aa154',
  'pineglade-400': '#088752',
  'pineglade-300': '#066d50',
  'pineglade-200': '#04534e',
  'pineglade-100': '#02394c',
  'pineglade-50': '#001f4a',
};

export const SPACING_SCALE = {
  xxxsmall: '2px',
  xxsmall: '4px',
  xsmall: '8px',
  small: '12px',
  medium: '16px',
  large: '24px',
  xlarge: '32px',
  xxlarge: '40px',
  xxxlarge: '48px',
  xxxxlarge: '56px',
  xxxxxlarge: '64px',
};

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      ...BASIC_COLORS,
      ...SILVERMIST_COLORS,
      ...LUPINE_COLORS,
      ...BLOODMOON_COLORS,
      ...SUNBARK_COLORS,
      ...PINEGLADE_COLORS,
    },
    // Stitches can't handle these font variables for some reason.
    // fonts: {
    //   sans: 'var(--font-inter), sans-serif',
    //   code: 'var(--font-fira-code), monospace',
    // },
    fontWeights: {
      normal: '400',
      medium: '500',
      semibold: '600',
    },
    fontSizes: {
      xsmall: '12px',
      small: '14px',
      medium: '16px',
      large: '20px',
      xlarge: '24px',
      xxlarge: '30px',
      xxxlarge: '42px',
    },
    lineHeights: {
      xsmall: '16px',
      small: '20px',
      medium: '24px',
      large: '28px',
      xlarge: '32px',
      xxlarge: '36px',
      xxxlarge: '48px',
    },
    letterSpacings: {
      large: '-0.0125em',
      xlarge: '-0.025em',
      xxlarge: '-0.025em',
      xxxlarge: '-0.05em',
    },
    space: SPACING_SCALE,
    sizes: {
      maxWidth: '1000px',
    },
    radii: {
      square: '2px',
      xsmall: '4px',
      small: '12px',
      default: '16px',
      round: '9999px',
    },
    shadows: {
      'elevation-small': '0px 1px 2px rgba(25, 23, 34, 0.1)',
      'elevation-large':
        '0px 42px 17px rgba(25, 24, 33, 0.01), 0px 24px 14px rgba(25, 24, 33, 0.02), 0px 11px 11px rgba(25, 24, 33, 0.03), 0px 3px 6px rgba(25, 24, 33, 0.04), 0px 0px 0px rgba(25, 24, 33, 0.04), inset 0px -1px 1px #D6D4E3',
      low: 'rgba(18, 18, 19, 0.06) 0px 2px 12px 0px',
      medium: 'rgba(18, 18, 19, 0.1) 0px 2px 14px 0px',
      high: 'rgba(18, 18, 19, 0.14) 0px 11px 44px 0px',
      button:
        '0px 2px 4px rgba(0, 0, 0, 0.08), inset 0px -1px 2px rgba(0, 0, 0, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.08);',
    },
    transitions: {
      slow: '200ms',
      medium: '100ms',
      fast: '50ms',
    },
  },
  media: {
    smallDevices: '(max-width: 600px)',
  },
  utils: {
    marginHorizontal: (value: number | string) => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginVertical: (value: number | string) => ({
      marginTop: value,
      marginBottom: value,
    }),
    paddingHorizontal: (value: number | string) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingVertical: (value: number | string) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    size: (value: number | string) => ({width: value, height: value}),
  },
});

export const darkTheme = createTheme('dark', {
  colors: {
    ...DARK_BASIC_COLORS,
    ...DARK_SILVERMIST_COLORS,
    ...DARK_LUPINE_COLORS,
    ...DARK_BLOODMOON_COLORS,
    ...DARK_SUNBARK_COLORS,
    ...DARK_PINEGLADE_COLORS,
  },
});

export const useGlobalStyles = globalCss({
  '@font-face': [
    {
      fontFamily: 'General Sans Variable',
      fontWeight: '400 600',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      src: 'url("/fonts/GeneralSans-Variable.ttf") format("truetype")',
    },
    {
      fontFamily: 'Inter',
      fontWeight: '400',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      src: 'url("/fonts/Inter-Regular.woff2") format("woff2")',
    },
    {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      src: 'url("/fonts/Inter-Medium.woff2") format("woff2")',
    },
    {
      fontFamily: 'Inter',
      fontWeight: '600',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      src: 'url("/fonts/Inter-SemiBold.woff2") format("woff2")',
    }
  ],
  body: {
    margin: 0,
    minHeight: '100vh',
    color: '$silvermist-950',
    '-webkit-font-smoothing': 'antialiased',
    fontFamily: 'Inter, sans-serif',
  },
  a: {
    color: '$lupine-600',
    '&:hover': {
      color: '$lupine-500',
    },
  },
  '::selection': {
    background: '$lupine-100',
  },
  ...normalize[0],
  ...normalize[1],
});
