/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AxiosError } from 'axios';
import useSWR, { SWRConfiguration } from 'swr';
import { UserResponse } from './data-contracts';

/**
 * Get the current user.
 */
export const useCurrentUser = (config?: SWRConfiguration<UserResponse>) => {
  return useSWR<UserResponse, AxiosError>(`/me`, config);
};
